// src/DataTable.js
import './components.css';
import React, { useState, useEffect, useRef} from "react";
import { Table, Button, Modal, Select, Form, Input, ConfigProvider, List, Card, Tag, Typography, Divider, Menu, Dropdown, Pagination, Checkbox } from 'antd';
// import Loading from "./Loading";
import { industryArray,areaArray,postArray,genderArray,findValueByKey,ageArray} from '../common';
import { CheckCircleFilled, EllipsisOutlined } from '@ant-design/icons';

const UsersListTable = () => {
  const [isFixed, setIsFixed] = useState(false);
  const toolbarRef = useRef(null);
  const listRef = useRef(null);

  const [form] = Form.useForm();
  const [adjustedHeight, setAdjustedHeight] = useState(0);

  // const [isLoad,setIsLoad] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [usersArray,setUsersArray] = useState([]);
  const [filterUsersArray,setFilterUsersArray] = useState([]);
  const [dataTextArray,setDataTextArray] = useState([]);
  const [isModal,setIsModal] = useState(false);
  const [selectIndex,setSelectIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const {Option} = Select;

  const [isDetail,setIsDetail] = useState(false);
  const [requestLimit,setRequestLimit] = useState(5);
  

  const [selectPost, setSelectPost] = useState(undefined);
  const [selectGender, setSelectGender] = useState(undefined);
  const [selectAge, setSelectAge] = useState(undefined);
  const [selectArea, setSelectArea] = useState(undefined);
  const [selectIndustry, setSelectIndustry] = useState(undefined);
  const [selectAuth, setSelectAuth] = useState(undefined);
  const [freeWord, setFreeWord] = useState(undefined);
  const [acceptCount,setAcceptCount] = useState(0);


  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleDetail = (index) => {
    setSelectIndex(index+(currentPage-1)*pageSize)
    setIsModal(true);
    setIsDetail(true);
  }

  const handleRequest = (index) => {
    setSelectIndex(index+(currentPage-1)*pageSize)
    setIsModal(true);
    setIsRequest(true);
  }

  const handleSearch = () => {
    setIsModal(true);
    setIsSearch(true);
  }

  const handleCancel = () => {
    setIsModal(false);
    setIsDetail(false);
    setIsSearch(false);
    setIsRequest(false);
  }

  const handleReset = () => {
    setSelectPost(undefined);
    setSelectGender(undefined);
    setSelectAge(undefined);
    setSelectArea(undefined);
    setSelectIndustry(undefined);
    setFreeWord(undefined);
    form.resetFields();
    setFilterUsersArray(usersArray);
    setIsModal(false);
    setIsSearch(false);
    setSelectAuth(false);

  }

  const hadnleSearch = () =>{
    let copyArray = usersArray;
    if(selectPost !== undefined){
      copyArray = copyArray.filter(user => user.post === selectPost);
    }
    if(selectGender !== undefined){
      copyArray = copyArray.filter(user => user.gender === selectGender);
    }
    if(selectAge !== undefined){
      copyArray = copyArray.filter(user => getDecadeKey(user.birthDate) === selectAge);
    }
    if(selectArea !== undefined){
      copyArray = copyArray.filter(user => user.area === selectArea);
    }
    if(selectIndustry !== undefined){
      copyArray = copyArray.filter(user => user.industry === selectIndustry);
    }
    if(freeWord !== undefined && freeWord !== ""){
      copyArray = copyArray.filter(user => 
        (user.businessSummary && user.businessSummary.includes(freeWord)) ||
        (user.introductionText && user.introductionText.includes(freeWord))
      );
    }
    if(selectAuth){
      copyArray = copyArray.filter(user => user.isAuth === 1 && user.isBreach === 0);
    }

    setFilterUsersArray(copyArray);
    setIsModal(false);
    setIsSearch(false);
  }

  const updateRequest = async() =>{
    setIsModal(false);
    setIsRequest(false);
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+'/profile/request', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({LINEID:filterUsersArray[selectIndex].LINEID}),
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
            alert("エラーが発生しました");
          }
          else{
            alert("お繋ぎ希望が完了しました");
            window.location.reload();
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
        alert("エラーが発生しました");
    }
  }


  useEffect(() => {
    init();
    const handleScroll = () => {
      if (toolbarRef.current && listRef.current) {
        const toolbarTop = toolbarRef.current.getBoundingClientRect().top;
        const listTop = listRef.current.getBoundingClientRect().top;
        if (toolbarTop <= 0 && !isFixed) {
          setIsFixed(true);
        } 
        if (listTop > 0) {
          setIsFixed(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  },[]);

  const init = async()=>{
    // setIsLoad(true);
    await getUserInfo();
    await getPostBackData();
    await getAllUsers();
    await getAcceptCount();
// setIsLoad(false);
  }

  const getUserInfo = async()=>{
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+"/profile/get/introducedData", {
          method: 'GET',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
          }
          else{
            console.log(data.userInfo)
            setUserInfo(data.userInfo);
            setRequestLimit(data.userInfo.plan > 1 ? 15 : 5);
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
  }

  const getPostBackData = async()=>{
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+"/postBackData/get", {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({dataTextPart:"&requestList"}),
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
          }
          else{
            if(data.dataTextArray){
              setDataTextArray(data.dataTextArray.map(getOtherLINEID));
            }
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
  }

  const getAcceptCount = async()=>{
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+"/postBackData/get/acceptCount", {
          method: 'GET',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
          }
          else{
            if(data.acceptCount){
              setAcceptCount(data.acceptCount);
            }
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
  }

  const getAllUsers = async()=>{
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+"/users/profileComp", {
          method: 'GET',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
          }
          else{
            setUsersArray(data.usersArray)
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
  }

  const getAuthUsers = async()=>{
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+"/users/public", {
          method: 'GET',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
          }
          else{
            setUsersArray(data.usersArray)
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
  }

  useEffect(() => {
    setFilterUsersArray(usersArray)
  },[usersArray]);

  useEffect(() => {
    setCurrentPage(1);
  },[filterUsersArray]);

  function getOtherLINEID(str) {
    const [dataType, MYLINEID, otherLINEID] = str.dataTxt.split('=');
    return otherLINEID;
  }

  function getDecade(birthDate) {
    const today = new Date();
    const birth = new Date(birthDate);
    
    // 年齢を計算
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();
    
    // 誕生日がまだ来ていない場合、年齢を1引く
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
        age--;
    }
    
    // 年齢から年代を計算 (例: 27歳 => "20代", 35歳 => "30代")
    const decade = Math.floor(age / 10) * 10;
    
    return `${decade}代`;
  }

  function getDecadeKey(birthDate) {
    const today = new Date();
    const birth = new Date(birthDate);
    
    // 年齢を計算
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();
    
    // 誕生日がまだ来ていない場合、年齢を1引く
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
        age--;
    }
    
    // 年齢から年代を計算 (例: 27歳 => "20代", 35歳 => "30代")
    const decade = Math.floor(age / 10);
    
    return decade > 10 ? 10 : decade;
  }

  const paginatedData = filterUsersArray.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: '#07DFD8',
              algorithm: true,
            },
            Input: {
              colorPrimary: '#07DFD8',
              algorithm: true,
            },
            Radio:{
              colorPrimary: '#07DFD8',
              algorithm: true,
            },
            Select: {
              colorPrimary: '#07DFD8',
              algorithm: true,
            },
            Checkbox: {
              colorPrimary: '#07DFD8',
              algorithm: true,
            },
          },
        }}
      >
        <Modal
          open={isModal}
          onCancel={() => handleCancel()}
          footer={[
            <>
              {isDetail &&
                <div style={{wordWrap:"break-word"}}>
                {(filterUsersArray.length > 0 && isModal) &&
                  <div style={{textAlign:"start",margin:"20px 5px"}}>
                    <div  style={{margin:"3px 0"}}>
                      <div>【{filterUsersArray[selectIndex].lastName}{filterUsersArray[selectIndex].firstName}さんの自己紹介文】</div>
                      <div style={{marginLeft:"15px",whiteSpace:"pre-wrap"}}>{(filterUsersArray[selectIndex].introductionText).split('\n').map(line => line.trimStart()).join('\n')}</div>
                    </div>
                  </div>
                }
                <Button type='primary' style={{margin:"10px 0"}} onClick={() => handleCancel()}>
                  閉じる
                </Button>
              </div>
              }
              {isRequest &&
                <>
                  {(userInfo && requestLimit <= userInfo.requestListNum) ? (
                    <>
                      <div style={{textAlign:"start",color:"red"}}>お繋ぎ希望数が上限に達しています。</div>
                      <div style={{textAlign:"start"}}>次回更新日の{(new Date(userInfo.planNextDate)).getFullYear() + "/" + ((new Date(userInfo.planNextDate)).getMonth()+1) + "/" + (new Date(userInfo.planNextDate)).getDate()}までお待ちください。</div>
                      <Button type='primary' style={{margin:"10px 0"}} onClick={() => handleCancel()}>
                        閉じる
                      </Button>
                    </>
                  ) : (
                    <>
                      <div style={{textAlign:"start"}}>{filterUsersArray[selectIndex].lastName}{filterUsersArray[selectIndex].firstName}さんをお繋ぎ希望しますか？</div>
                      <Button type='primary' style={{margin:"10px 5px"}} onClick={() => updateRequest()}>
                        はい
                      </Button>
                      <Button key="cancel" style={{margin:"10px 5px"}} onClick={() => handleCancel()}>
                        いいえ
                      </Button>
                    </>
                  )}
                </>
              }
              {isSearch &&
                <>
                  <div style={{textAlign:"start"}}>
                  <Form form={form}>
                  <Form.Item
                    name="post"
                    label={<span style={{fontSize:"120%"}}>役職</span>}
                    labelCol={{span:24}}
                  >
                   <Select
                        style={{fontSize:"120%", width: '100%'}}
                        allowClear
                        onChange={e=>setSelectPost(e)}
                        value={selectPost}
                    >
                      {postArray.map((item) => (
                          <Option value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Option>
                      ))}
                    </Select>
                  </Form.Item>
                <Form.Item
                  name="gender"
                  label={<span style={{fontSize:"120%"}}>性別</span>}
                  labelCol={{span:24}}
                >
                   <Select
                        style={{fontSize:"120%", width: '100%'}}
                        allowClear
                        onChange={e=>setSelectGender(e)}
                        value={selectGender}
                    >
                      {genderArray.map((item) => (
                          <Option value={item.key}>{item.value}</Option>
                      ))}
                    </Select>
                </Form.Item>
                <Form.Item
                  name="age"
                  label={<span style={{fontSize:"120%"}}>年代</span>}
                  labelCol={{span:24}}
                >
                   <Select
                        style={{fontSize:"120%", width: '100%'}}
                        allowClear
                        onChange={e=>setSelectAge(e)}
                        Value={selectAge}
                    >
                      {ageArray.map((item) => (
                          <Option value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Option>
                      ))}
                    </Select>
                </Form.Item>
                <Form.Item
                  name="area"
                  label={<span style={{fontSize:"120%"}}>活動拠点</span>}
                  labelCol={{span:24}}
                >
                    <Select
                        style={{fontSize:"120%", width: '100%'}}
                        allowClear
                        value={selectArea}
                        onChange={e=>setSelectArea(e)}
                    >
                        {areaArray.map((item) => (
                            <Option value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                  name="industry"
                  label={<span style={{fontSize:"120%"}}>業種</span>}
                  labelCol={{span:24}}
                >
                    <Select
                        style={{fontSize:"120%", width: '100%'}}
                        allowClear
                        onChange={e=>setSelectIndustry(e)}
                        value={selectIndustry}
                    >
                        {industryArray.map((item) => (
                            <Option value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                  name="free"
                  label={<span style={{fontSize:"120%"}}>フリーワード</span>}
                  labelCol={{span:24}}
                >
                  <Input
                    style={{fontSize:"120%"}}
                    onChange={e=> setFreeWord(e.target.value)}
                    value={freeWord}
                    type="text"
                  />
                </Form.Item>
                <Checkbox
                  name="auth"
                  checked={selectAuth}
                  onChange={e => setSelectAuth(!selectAuth)}
                  style={{marginBottom:"20px"}}
                >
                  <span style={{fontSize:"120%"}}>認証ユーザー<CheckCircleFilled style={{color:'#07DFD8'}} />のみを表示する</span>
                </Checkbox>
                </Form>
                  </div>
                  <Button type='primary' onClick={() => hadnleSearch()}>
                    絞り込む
                  </Button>
                  <Button key="cancel" onClick={() => handleReset()}>
                    リセット
                  </Button>
                </>
              }
            </>
          ]}
        >
      </Modal>
      {/* <Loading loading={isLoad}/> */}
      <div style={{fontSize:"90%"}}>下記のユーザーリストの「・・・」メニューの中の「自己紹介文を見る」からユーザーの自己紹介を確認できます。また繋がりたい方がいましたら「お繋ぎ希望」を押してください。</div>
      <div style={{fontSize:"90%",marginTop:"10px"}}><CheckCircleFilled style={{color:'#07DFD8'}} />は運営によるプロフィールの審査を通過しているユーザーの認証マークです</div>
      <div style={{marginTop:"10px"}}>お繋ぎ希望数：<span style={{fontWeight:"bold"}}>{userInfo && (<>{userInfo.requestListNum} / {requestLimit}</>)}</span></div>
      <div style={{marginTop:"10px"}}>マッチング数：<span style={{fontWeight:"bold"}}>{userInfo && (<>{acceptCount}</>)}</span></div>
      {(userInfo && requestLimit <= userInfo.requestListNum) &&
        <>
          <div style={{color:"red",fontSize:"90%"}}>希望数が上限に達しました。</div>
          <div style={{fontSize:"90%"}}>次回更新日の{(new Date(userInfo.planNextDate)).getFullYear() + "/" + ((new Date(userInfo.planNextDate)).getMonth()+1) + "/" + (new Date(userInfo.planNextDate)).getDate()}までお待ちください。</div>
        </>
      }

      <div style={{display:"flex",justifyContent:"space-between",margin:"5px"}} 
        ref={toolbarRef}
        className={isFixed ? 'fixed-toolbar' : ''}
      >
        <div style={{margin:"auto 0"}}>
          <Button type="primary" style={{margin:"5px 0"}} onClick={() => handleSearch()}>
            絞り込む
          </Button>
        </div>
        <div style={{margin:"auto 0"}}>
          <Pagination
              current={currentPage}
              pageSize={pageSize}
              onChange={handlePageChange}
              total={filterUsersArray.length}
              showSizeChanger
              showLessItems
              pageSizeOptions={['10', '20', '50', '100']}
            />
          </div>
      </div>
      <div ref={listRef}>
        <List
          itemLayout="vertical"
          dataSource={paginatedData}
          style={{paddingBottom:"10px"}}
          renderItem={(item,index) => {
            const menu = (
              <Menu>
                <Menu.Item key="1" onClick={() => handleDetail(index)}>自己紹介文をみる</Menu.Item>
                {filterUsersArray[index+(currentPage-1)*pageSize] && 
                  <>
                    {dataTextArray.includes(filterUsersArray[index+(currentPage-1)*pageSize].LINEID) ? (
                      <Menu.Item key="2">お繋ぎ希望済み</Menu.Item>
                    ) : (
                      <Menu.Item key="2" onClick={() => handleRequest(index)}>お繋ぎ希望</Menu.Item>
                    )}
                  </>
                }
              </Menu>
            );
            return (
              <Card style={{ marginBottom: 8}} size='small' title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', gap: 4}}>
                    <Tag color="green">{getDecade(item.birthDate)}</Tag>
                    {item.gender !== 2 && <Tag color={item.gender == 0 ? "blue" : "pink"}>{findValueByKey(item.gender,genderArray)}</Tag>}
                    <Tag color="orange">{findValueByKey(item.area,areaArray)}</Tag>
                    {(item.isAuth == 1 && item.isBreach == 0) && <CheckCircleFilled style={{color:'#07DFD8'}} />}
                    {/* <div>
                      承諾率：
                      {item.acceptNumTotal == 0 ? (
                        <>0%</>
                      ) : (
                        <>
                          {(item.acceptNumTotal/item.wasRequestedNumTotal*100).toFixed(1)}%
                        </>
                      )}
                    </div> */}
                  </div>
                  <Dropdown overlay={menu} trigger={['click']}>
                    <EllipsisOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
                  </Dropdown>
                </div>
              }>
                {dataTextArray.includes(filterUsersArray[index+(currentPage-1)*pageSize].LINEID) && <span style={{color:"red",fontWeight:"bold"}}>お繋ぎ希望済み</span>}
                <div style={{marginBottom: 5 }}>
                <Typography.Text strong>{item.lastName}{item.firstName}</Typography.Text>　<Typography.Text style={{fontSize:"90%"}}>{item.companyName} {item.companyName && <>-</>} {findValueByKey(item.post,postArray)}</Typography.Text>
                </div>
                <Typography.Text>【{findValueByKey(item.industry,industryArray)}】</Typography.Text>
                <div style={{margin:"0 6px"}}>
                  <Typography.Text>{item.businessSummary}</Typography.Text>
                </div>
              </Card>
          )}}
        />
        </div>
      </ConfigProvider>
    </>
   
  );
};

export default UsersListTable;
