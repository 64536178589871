import './App.css';
import React, { useEffect, useState } from "react";
import { AdvertiseDetail } from './components';
import { useNavigate} from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Advertise = () => {
const navigate = useNavigate();
const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const hashLINEID = queryParams.get('id');

const [isLogin,setIsLogin] = useState(false);
useEffect(()=>{
  if(hashLINEID === null){
    navigate('/Error');
  }
  init();
},[]);

const init = async() =>{
    if(await checkLINELogin()){
      if(!await startLogin()){
        navigate('/Error');
      }
      else{
        setIsLogin(true);
      }
    }
    else{
      await startLINEAuth();
    }
  }

  async function startLogin(){
    try {
      const response = await fetch(process.env.REACT_APP_API_DIR+'/login/premium', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({hashLINEID}),
      });
  
      const data = await response.json();
      if(data){
        return data.isLogin;
      }
      else{
        return false;
      }
    } catch (error) {
        navigate('/Error');
        console.error('Fetch error:', JSON.stringify(error));
    }
  }
  
  async function startLINEAuth(){
    const redirectUri = encodeURIComponent(`${process.env.REACT_APP_API_DIR}/login/callback`);
    const state = generateRandomString(8);
    const scope = 'profile openid';
    const authUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.REACT_APP_CHANNEL_ID}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;
    window.location.href = authUrl;
  }
  
  function generateRandomString(length) {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }
  
  async function checkLINELogin(){
    try {
      const response = await fetch(process.env.REACT_APP_API_DIR+'/login/check', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({accessOringn:"/Advertise?id=",parameter:hashLINEID}),
      });
  
      const data = await response.json();
      if(data){
        return data.isLogin;
      }
      else{
        return false;
      }
    } catch (error) {
        navigate('/Error');
        console.error('Fetch error:', JSON.stringify(error));
    }
  }

  return (
    <div className='back-content'>
      <div className='table-content'>
        <h2 className='page-title' style={{margin:0,padding:"20px 0"}}>広告宣伝</h2>
        {isLogin && <AdvertiseDetail/>}
      </div>
    </div>
  );
};

export default Advertise;
